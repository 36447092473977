.CardContainer {
    display: flex;
    flex-direction: row;
    width: auto;
}

@media (max-width: 576px) {
    .CardContainer {
        display: flex;
        flex-direction: column;
        width: auto;
    }
}
