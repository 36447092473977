.office-card {
  margin-left: 20px;
}

.office-card-content {
  height: 176px;
}

.office-text-details {
  height: 135px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 576px) {
  .office-card {
    margin-left: 0px;
  }
}
