@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'Dosis', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #FAFAFA url(img/esfera-bg.svg) no-repeat fixed center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.hidden-not-sm {
  display: none;
}
.hidden-sm {
  display: block;
}

@media (max-width: 576px) {
  .hidden-not-sm {
    display: block!important;
  }
  .hidden-sm {
    display: none!important;
  }
}
