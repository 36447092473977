.card-subtitle {
    margin: 18px 0;
}

a {
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
}

.feedback-text {
    text-align: center;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 20px;
}
