.kebab-icon{
	width: 5px;
    position: fixed;
    z-index: 0;
}

.kebab-menu-container{
    margin-left: 10px;
    margin-right: 10px;
    height: 32px;
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 10px;
	&:hover,
	&:focus,
	&:active {
		cursor: pointer;
	}
    z-index: 0;
}

.kebab-button-container{
    position: absolute;
	background-color: white;
	height: 66px;
	width: 107px;
	border-bottom-right-radius: 14px;
	border-bottom-left-radius: 14px;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 76px;
    margin-top: 125px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
    background-color: black;
	z-index: 1;
}
