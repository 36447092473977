@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;700&display=swap');

.people-card {
  margin: 0 0 30px 20px;
  padding: 26px 35px;
  height: fit-content;
  width: 312px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  position: relative;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}

.card-title {
  margin: 0 0 18px;
}

.card-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card-content-item {
  display: flex;
  justify-content: space-between;
  margin: 0 0 3px;
}

.card-content-item-medal {
  width: 20px;
}

.full-ranking-alert-container {
  width: 243px;
  margin-top: 10px;
  text-align: center;
  border: 3px dashed #eb6565;
  padding: 0px 8px 8px;
  border-radius: 16px;
}

.full-ranking-alert-message-title {
  color: black;
  font-weight: 700;
}

.full-ranking-alert-message {
  color: black;
  font-weight: 500;
}

.full-ranking-alert-message-info {
  color: black;
  font-weight: 400;
  font-size: 12px;
}

.full-ranking-alert-container-actions {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.button-container {
  width: 243px;
  margin-top: 30px;
}