@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;700&display=swap');

.card {
  margin: 0 0 30px;
  padding: 26px 35px;
  height: auto;
  width: 312px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  position: relative;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}

.card-title {
  margin: 0 0 18px;
}

.card-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.large-input {
  width: 100%;
  height: 32px;
  font-family: 'Dosis', sans-serif;
  font-size: 16px;
  border: 1px solid #8E3BFF !important;
  border-radius: 14px;
  outline: none;
  margin-top: 6px;
  margin-bottom: 14px;
  box-sizing: border-box;
  background-color: #fff;
  padding-left: 12px;
}

select {
  padding-left: 8px !important;
}

.checkbox-container {
  margin-bottom: 10px;
}

.button-container {
  width: 243px;
  margin-top: 10px;
}

.booking-message {
  background-color: #8E3BFF;
  color: #fff;
  font-weight: 500;
}
