.assistants-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
}

.no-booking-text-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  width: 80%;
}

.assistants-card {
  width: 300px;
  min-height: 550px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 15px;
  box-sizing: border-box;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  overflow: hidden;
}

h1 {
  color: #fff;
  font-weight: 600;
}

.assistants-card-header {
  height: 84px;
  background-color: #8E3BFF;
  color: #fff;
  width: 100%;
  text-align: center;
}

.availability-text{
  margin-top: 8px;
}

h3 {
  margin-bottom: 0;
}

.assistants-list {
  padding: 0;
  width: 90%;
}

li {
  list-style-type: none;
}

.assistant {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 44px;
  margin-bottom: 7px;
}

.text-container {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-weight: 600;
  text-overflow: ellipsis;
  max-width: 166px;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
}

.key-arrival-time {
  color:#8E3BFF;
  font-size: 14px;
  font-weight: 600;
}

.icon-container {
  display: flex;
  align-items: center;
}

.round-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #8E3BFF;
  margin-left: 7px;
}

.icon-button {
  margin-left: 10px;
  background-color: transparent;
  border: none;
  padding: 0;
  padding-top: 3px;
}

.icon {
  color: #8E3BFF;
  font-size: 18px;
}

.horizontal-spacer {
  width: 26px;
}
