nav {
  padding-left: 30px;
  padding-right: 10px;
  margin-bottom: 30px;
  box-sizing: border-box;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
}

.logo {
  margin-top: 5px;
  width: 100px;
}

#user-name-container {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
}

#profile-avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-left: 10px;
}

.header-user-content {
  display: flex;
  flex-direction: row !important;
}

.header-button-text {
  margin-left: 5px;
}

.greeting-text {
  margin-left: 10px;
}

.header-button-container button {
  width: auto;
  padding: 6px 25px;
  margin-right: 15px;
}

.header-button-badge {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.header-button-badge span {
  background-color: #12e9ba;
  color: #6d2929;
  border-radius: 25%;
  padding: 2px 5px;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 576px) {
  .header-button-container button {
    padding: 6px 10px!important;
    margin-right: 5px!important
  }

  #profile-avatar {
    margin-left: 8px;
  }

  .greeting-text {
    margin-left: 8px;
    width: 65px;
  }
}
