.info {
    margin: 0 0 30px;
    padding: 10px 35px;
    height: auto;
    width: 50%;
    box-sizing: border-box;
    align-items: center;
    flex-direction: column;
    background-color: #d1cece;
    position: relative;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    z-index: 0;
}

.list {
    padding-left: 20px;
}

li {
    list-style: square;
    margin-bottom: 10px;
}

.info-button-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    width: 100%;
}

.info-button-context {
    width: 243px;
}

@media (max-width: 576px) {
   .info {
       width: 312px;
   }

  .info-button-container {
    display: flex;
    flex-direction: row;
    align-content: center;
  }
}

