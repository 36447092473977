.RankingCardContainer {
    display: flex;
    flex-direction: row;
    width: auto;
}

@media (max-width: 1000px) {
    .RankingCardContainer {
        display: flex;
        flex-direction: column;
        width: auto;
        place-items: center;
    }
}
