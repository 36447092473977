@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;700&display=swap');

.button {
    background-color: #8E3BFF;
    color: #fff;
    font-family: 'Dosis', sans-serif;
    font-weight: 700;
    font-size: 14px;
    padding: 6px 10px;
    border: none;
    width: 100%;
    height: 32px;
    border-radius: 14px;
}

.button:hover { 
    background-color: #E941A0;
    cursor: pointer;
}

.button-content-container {
    display: flex;
    justify-content: center;
    align-items: center
}


.disabled {
    background-color: #ccc;
    color: #fff;    
}

.disabled:hover {
    background-color: #ccc;
    color: #fff;
    cursor: default;
}