@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;700&display=swap');

.combos-card {
  margin: 0 0 30px 20px;
  padding: 26px 35px;
  height: fit-content;
  width: 400px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  position: relative;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}

.card-title {
  margin: 0 0 18px;
}

.card-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card-content-item {
  display: flex;
  justify-content: space-between;
  margin: 0 0 3px;
}

.card-content-item-medal {
  width: 20px;
}
